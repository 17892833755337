$maxWidth: 1170px;
$mainTextColor: #fff;
$subTextColor: #333;

#home {
    .fog-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;
        filter: blur(10px);

        .fog-img {
            position: absolute;
            height: 100vh;
            width: 300vw;
            z-index: 2;
            left: 0;
            top: 0;

            @media only screen and (max-width: $mobileWidth) {
                display: none;
            }
        }

        .fog-img-first {
            background: url('../images/fog-1.png');
            background-repeat: repeat-x;
            background-size: contain;
            background-position: center;
            animation: marquee 60s linear infinite;
        }

        .fog-img-second {
            background: url('../images/fog-2.png');
            background-repeat: repeat-x;
            background-size: contain;
            background-position: center;
            opacity: 0.2;
            animation: marquee 30s linear infinite;
        }
    }

    @keyframes marquee {
        0% {
            transform: translate3d(0, 0, 0);
        }

        100% {
            transform: translate3d(-200vw, 0, 0);
        }
    }
    &.container__wrapper {
        padding: 150px 0;
        margin: 0 auto;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 100vh;
        text-align: center !important;

        &.bg__image {
            background-image: url('https://images.unsplash.com/photo-1519120944692-1a8d8cfc107f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80');
            background-size: cover;
            background-position: 0% 40%;
            background: rgba(243, 187, 124, 0.6);
            background: -moz-linear-gradient(
                    right,
                    rgba(187, 112, 30, 0.88) 0%,
                    rgba(243, 187, 124, 0.6) 100%
                ),
                url('https://images.unsplash.com/photo-1519120944692-1a8d8cfc107f?auto=format&fit=crop&ixlib=rb-1.2.1&q=80&w=1352')
                    50% 20% no-repeat;
            background: -webkit-gradient(
                    linear,
                    left top,
                    left bottom,
                    color-stop(0%, rgba(187, 112, 30, 0.88)),
                    color-stop(100%, rgba(243, 187, 124, 0.6))
                ),
                url('https://images.unsplash.com/photo-1519120944692-1a8d8cfc107f?auto=format&fit=crop&ixlib=rb-1.2.1&q=80&w=1352')
                    50% 20% no-repeat;
            background: -webkit-linear-gradient(
                    right,
                    rgba(187, 112, 30, 0.88) 0%,
                    rgba(243, 187, 124, 0.6) 100%
                ),
                url('https://images.unsplash.com/photo-1519120944692-1a8d8cfc107f?auto=format&fit=crop&ixlib=rb-1.2.1&q=80&w=1352')
                    50% 20% no-repeat;
            background: -o-linear-gradient(
                    right,
                    rgba(187, 112, 30, 0.88) 0%,
                    rgba(243, 187, 124, 0.6) 100%
                ),
                url('https://images.unsplash.com/photo-1519120944692-1a8d8cfc107f?auto=format&fit=crop&ixlib=rb-1.2.1&q=80&w=1352')
                    50% 20% no-repeat;
            background: -ms-linear-gradient(
                    right,
                    rgba(187, 112, 30, 0.88) 0%,
                    rgba(243, 187, 124, 0.6) 100%
                ),
                url('https://images.unsplash.com/photo-1519120944692-1a8d8cfc107f?auto=format&fit=crop&ixlib=rb-1.2.1&q=80&w=1352')
                    50% 20% no-repeat;
            background: linear-gradient(
                    to right,
                    rgb(216 136 50) 0%,
                    rgb(222 142 53 / 60%) 100%
                ),
                url(https://images.unsplash.com/photo-1519120944692-1a8d8cfc107f?auto=format&fit=crop&ixlib=rb-1.2.1&q=80&w=1352)
                    50% 20% no-repeat;
        }

        .container {
            width: 100%;
            max-width: 640px;
            z-index: 8;

            .header__title,
            .header__sub__title {
                display: inline-block;
                margin-bottom: 10px;
                border-left: 0;
                margin-top: 10px;
            }

            .header__title {
                color: $mainTextColor;
                width: 100%;
                text-align: center;
                margin-bottom: 0;
                font-size: 80px;
                font-weight: bold;
                letter-spacing: 4px;

                span {
                    color: $subTextColor;
                }

                @media screen and (max-width: $mobileWidth) {
                    font-size: 40px;
                    margin-bottom: 20px;
                }
            }

            .header__sub__title {
                color: $mainTextColor;
                font-size: 16px;
                background: #ffffff14;
                padding: 0 30px;
                letter-spacing: 1.75px;

                a {
                    color: $mainTextColor;
                    transition: 0.2s opacity;

                    &:hover {
                        opacity: 0.7;
                    }
                }

                @media screen and (max-width: $mobileWidth) {
                    display: flex;
                    flex-direction: column;
                    padding: 10px 20px;

                    .divider__line {
                        display: none;
                    }
                }
            }
        }

        .social__wrapper {
            .social__links {
                max-width: 150px;
                text-align: center;
                margin-top: 30px;
                display: flex;
                justify-content: space-between;

                svg.social__link__icon {
                    fill: $mainTextColor;
                    height: 40px;
                    width: 40px;
                    padding: 10px;
                    transition: all 0.2s;
                    &:hover {
                        opacity: 0.7;
                        transform: translateY(-2px);
                    }
                }
            }
        }
    }
}
