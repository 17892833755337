#footer {
    &.container__wrapper {
        padding: 50px 20px;
        background: #f8f8f8;
        background: -webkit-linear-gradient(to right, #f8f8f8, #fbfbfb);
        background: linear-gradient(to right, #f8f8f8, #fbfbfb);
        display: flex;
        -ms-flex-align: center;
        justify-content: center;
        min-height: calc(100vh - 80px);
        position: relative;

        .container {
            max-width: 600px;
            display: flex;
            flex-direction: column;
            @media only screen and (min-width: $mobileSmallWidth) {
                margin-top: 60px;
            }

            .row {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                flex: 1 0 auto;

                .contact__form__wrapper {
                    margin-top: 30px;
                }
            }
        }
    }

    .contact__form__wrapper {
        .form__group {
            position: relative;
            padding: 15px 0 0;
            width: 100%;

            input:not(:placeholder-shown) ~ label,
            textarea:not(:placeholder-shown) ~ label {
                padding-top: 0;
                padding-bottom: 0;
                color: #777;
                font-size: 0.6rem;
            }

            input:-webkit-autofill ~ label,
            textarea:-webkit-autofill ~ label {
                font-size: 0.6rem;
                padding-top: 0;
                padding-bottom: 0;
                color: #777;
            }
        }

        .input__label {
            position: absolute;
            top: 5px;
            display: flex;
            align-items: center;
            transition: 0.2s;
            color: #666;
            letter-spacing: 1px;
        }

        .input__group {
            position: relative;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-align: stretch;
            align-items: stretch;
            width: 100%;
            margin-bottom: 20px;
        }

        .form__group:last-of-type {
            margin-top: 50px;
            @media only screen and (max-width: $mobileSmallWidth) {
                margin-bottom: 60px;
            }
        }

        .form__input {
            display: block;
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0;
            color: #111;
            background-color: transparent;
            background-clip: padding-box;
            border: 0;
            outline: none;
            border-bottom: 1px solid #666;
            font-size: 1rem;
            font-weight: 300;
            background: transparent;
            letter-spacing: 1px;
            box-shadow: none;
            transition: all 0.2s;

            &:-internal-autofill-selected {
                font-family: 'Lato', sans-serif;
                background-color: #fbfbfb !important;
                box-shadow: 0 0 0 50px #fbfafb inset;
                -webkit-box-shadow: 0 0 0 50px #fbfafb inset;
                font-size: 0.96rem;
                -webkit-text-stroke-width: 0.5px;
            }
            &:-webkit-autofill::first-line {
                font-family: 'Lato', sans-serif;
            }

            &::placeholder {
                color: transparent;
            }

            &:placeholder-shown ~ .input__label {
                cursor: text;
                top: 20px;
                display: flex;
                align-items: center;
                pointer-events: none;
                font-size: 13px;
            }

            &:focus {
                ~ .input__label {
                    position: absolute;
                    top: 5px;
                    transition: 0.2s;
                    color: #e4a260;
                    font-size: 0.6rem;
                }
                border-bottom: 1px solid #e4a260;
            }
        }

        .form__input:focus {
            border-color: $themeColor;
        }

        select.form__input[size],
        select.form__input[multiple] {
            height: auto;
        }

        textarea.form__input {
            height: auto;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: #111 !important;
        }

        @media (prefers-reduced-motion: reduce) {
            .form__btn__primary {
                transition: none;
            }
        }

        .form__btn__primary {
            color: #da933f;
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid #da933f;
            font-size: 13px;
            padding: 10px 1px;
            letter-spacing: 2px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            transition: all 0.15s ease-in-out;

            &:hover {
                cursor: pointer;
                color: $semiLightThemeColor;
                border-color: $semiLightThemeColor;
            }

            &:focus,
            &.focus {
                outline: none;
            }
        }
    }

    .social__wrapper {
        flex-shrink: 0;
        .social__links {
            max-width: 175px;
            display: flex;
            justify-content: space-evenly;
            .social__link {
                .social__link__icon {
                    fill: #000;
                    background-color: #f5e0c6;
                    border: 0;
                    height: 35px;
                    width: 35px;
                    padding: 10px;
                    transition: all 0.2s;

                    &:hover {
                        fill: #000;
                        background-color: #fbe4c9;
                        transform: translateY(-2px);
                    }
                }
            }
        }

        .personal__info {
            margin-top: 20px;

            display: flex;
            justify-content: center;

            .separator {
                margin: 0 10px;
                font-size: 16px;
                color: #999;
                margin-top: 1px;

                @media only screen and (max-width: $mobileSmallWidth) {
                    display: none;
                }
            }
            p {
                color: #888;
            }
            a {
                font-size: 13px;
                line-height: 26px;
                color: #666;
                letter-spacing: 1px;
                &:hover {
                    color: #222;
                }
            }
            @media only screen and (max-width: $mobileSmallWidth) {
                flex-direction: column;
            }
        }
    }
}
