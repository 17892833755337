#portfolio {
    background: #fff;

    &.container__wrapper {
        padding: 50px 20px;

        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: calc(100vh - 80px);
        position: relative;

        @media only screen and (min-width: $mobileWidth) {
            padding: 50px 20px 100px;
        }

        .container {
            max-width: 1260px;
            display: flex;
            flex-direction: column;

            .row {
                display: flex;
                flex-direction: column;

                .portfolio__item {
                    margin: 10px;
                    text-align: left;
                    margin: 0 auto;

                    @media only screen and (min-width: 900px) {
                        max-height: 400px;
                    }

                    .image__container {
                        margin-bottom: 10px;
                        display: flex;

                        @media only screen and (max-width: 900px) {
                            flex-direction: column;
                        }

                        .image__container__inner {
                            overflow: hidden;
                            margin-left: -20px;

                            @media only screen and (max-width: $mobileWidth) {
                                margin-left: 0;
                            }
                        }
                    }

                    .portfolio__content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;
                    }

                    .portfolio__btn__container {
                        margin-top: 1.5rem;
                        .portfolio__btn {
                            color: #da933f;
                            background-color: transparent;
                            border-bottom: 1px solid #da933f;
                            font-size: 13px;
                            padding: 0.375rem 0;
                            letter-spacing: 2px;
                            margin-right: 2rem;
                            transition: all 0.15s;

                            span {
                                margin-left: 0;
                                transition: all 0.15s;
                            }

                            &:hover {
                                margin-right: 1.5rem;
                                border-color: $semiLightThemeColor;
                                color: $semiLightThemeColor;
                                span {
                                    margin-left: 0.5rem;
                                    path {
                                        stroke: $semiLightThemeColor;
                                    }
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: $mobileWidth) {
                        margin: 0 0 70px 0;
                    }

                    .portfolio__image {
                        transform: translateX(-10px) scale(1.1);
                        z-index: 1;
                    }

                    h3 {
                        margin-bottom: 0.5rem;
                        font-weight: bold;
                        letter-spacing: 1px;
                    }

                    .category__tag__wrapper {
                        margin-bottom: 15px;
                        display: flex;
                        flex-wrap: wrap;

                        span {
                            background: #da933e;
                            padding: 0.25rem 0.666rem;
                            color: #fff;
                            border-radius: 15px;
                            margin: 0 7.5px 0.5rem 0;
                            font-size: 12px;
                            font-weight: 100;
                            letter-spacing: 0.5px;
                            line-height: initial;
                        }
                    }

                    @media only screen and (max-width: $mobileWidth) {
                        h3,
                        p {
                            text-align: left !important;
                        }
                    }
                }
            }
        }
    }
}
