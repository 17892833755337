$maxWidth: 1170px;

#about {
    background: #f8f8f8;

    &.container__wrapper {
        margin: 0 auto;
        padding: 50px 20px;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: calc(100vh - 80px);
        position: relative;

        .container {
            width: 100%;
            max-width: 900px;
            margin-right: auto;
            margin-left: auto;
            z-index: 1;
            vertical-align: middle;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .row {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -ms-flex-pack: center;
                justify-content: center;
                -ms-flex-align: center;
                align-items: center;

                .content__col {
                    padding-right: 30px;
                }
                .image__col {
                    box-shadow: 0 6px 60px 0 rgba(0, 0, 0, 0.25);
                    z-index: 1;
                    overflow: hidden;
                    filter: saturate(0.5);

                    .image__left__reveal {
                        position: absolute;
                        height: 100%;
                        width: 56%;
                        background: #f8f8f8;
                        left: 0;
                        top: 0;
                    }

                    .image__right__reveal {
                        position: absolute;
                        height: 100%;
                        width: 56%;
                        background: #ffffff;
                        right: 0;
                        top: 0;
                    }

                    img {
                        padding: 10px 0 0 15px;
                        background: #fff;
                        background: -webkit-linear-gradient(
                            to right,
                            #c9d6ff,
                            #e2e2e2
                        );
                        background: linear-gradient(to left, #ffffff, #f4f2f1);
                    }
                }
            }
        }

        .background__split {
            background-color: #ffffff;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: calc(50% + 250px);
        }
    }
}
